import useResizeAware from "react-resize-aware";

import "./App.scss";
import SecondSection from "./components/SecondSection/SecondSection";
import PartnersSection from "./components/PartnersSection/PartnersSection";
import SecuritySection from "./components/SecuritySection/SecuritySection";
import InfoSection from "./components/InfoSection/InfoSection";
import RegisterSection from "./components/RegisterSection/RegisterSection";
import FooterWeb from "./components/Footer/Footer";
import SecondSectionMobile from "./components/SecondSectionMobile/SecondSectionMobile";
import BoxesSection from "./components/BoxesSection/BoxesSection";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import business1 from "./assets/icons/business-1.svg";
import business2 from "./assets/icons/business-2.svg";
import business3 from "./assets/icons/business-3.svg";
import business from "./assets/icons/business.svg";
import businessMobile from "./assets/icons/businessMobile.svg";

import why1 from "./assets/icons/why-1.svg";
import why2 from "./assets/icons/why-2.svg";
import why3 from "./assets/icons/why-3.svg";
import why from "./assets/icons/why.svg";
import whyMobile from "./assets/icons/whyMobile.svg";

import groupStores from "./assets/groupStores.svg";
import FirstSectionWeb from "./components/FirstSection/FirstSection";
import HeaderWeb from "./components/Header/Header";
import HeaderMobile from "./components/HeaderMobile/Header";

import CBE from "./assets/logos/CBE.png";
import MDP from "./assets/logos/mdp.png";
import AAIB from "./assets/logos/AAIB.png";
import LDC from "./assets/logos/LDC.png";
import MEEZA from "./assets/logos/MEEZA.png";
import MASARY from "./assets/logos/masary.png";
import AFS from "./assets/logos/AFS.png";
import GRINTA from "./assets/logos/grinta.png";
import YALLA from "./assets/logos/yalla.png";
import SANDAH from "./assets/logos/sandah.png";
import BASATA from "./assets/logos/BASATA.png";
import SUPLYD from "./assets/logos/suplyd.png";
import IBNSINA from "./assets/logos/ibnsina.png";

import info1 from "./assets/icons/info-1.svg";
import info2 from "./assets/icons/info-2.svg";
import info3 from "./assets/icons/info-3.svg";
import info4 from "./assets/icons/info-4.svg";

import topUp from "./assets/icons/topup.svg";
import analyticsImage from "./assets/icons/analytics.svg";

import card from "./assets/icons/card.svg";
import managment from "./assets/icons/managment.svg";

const App = () => {
  const [resizeListener, sizes] = useResizeAware();
  const { t } = useTranslation();
  const infoList = {
    title: t("whoSection.title"),
    list: [
      {
        title: t("whoSection.freelancersTitle"),
        subTitle: t("whoSection.freelancersDescription"),
        icon: info1,
        color: "#D6E0FF",
      },
      {
        title: t("whoSection.commercialTitle"),
        subTitle: t("whoSection.commericalDescription"),
        icon: info2,
        color: "#FFEBD8",
      },
      {
        title: t("whoSection.smallbusinessTitle"),
        subTitle: t("whoSection.smallbusinessDescription"),
        icon: info3,
        color: "#EBD6FF",
      },
      {
        title: t("whoSection.startupsTitle"),
        subTitle: t("whoSection.startupsDescription"),
        icon: info4,
        color: "#FFD5DB",
      },
    ],
  };
  const partnersImages = [
    CBE,
    AAIB,
    MDP,
    LDC,
    MEEZA,
    BASATA,
    MASARY,
    AFS,
    GRINTA,
    YALLA,
    SANDAH,
    SUPLYD,
    IBNSINA,
  ];
  const businessBankingSection = {
    title: t("BusinessBankingSection.title"),
    subTitle: t("BusinessBankingSection.subTitle"),
    mainImage: business,
    mainImageMobile: businessMobile,
    backgroundColor: "#ffebd8",
    list: [
      {
        title: t("BusinessBankingSection.prepaidCardsTitle"),
        subTitle: t("BusinessBankingSection.prepaidCardsDescription"),
        icon: business1,
      },
      {
        title: t("BusinessBankingSection.posDevicesTitle"),
        subTitle: t("BusinessBankingSection.posDevicesDescription"),
        icon: business2,
      },
      {
        title: t("BusinessBankingSection.ewalletTitle"),
        subTitle: t("BusinessBankingSection.ewalletDescription"),
        icon: business3,
      },
    ],
  };
  const StryveAccountSection = {
    title: t("whySection.title"),
    subTitle: t("whySection.subTitle"),
    mainImage: why,
    mainImageMobile: whyMobile,
    backgroundColor: "#ebd6ff",
    list: [
      {
        title: t("whySection.freeAccountTitle"),
        subTitle: t("whySection.freeAccountDescription"),
        icon: why1,
      },
      {
        title: t("whySection.rewardingTitle"),
        subTitle: t("whySection.rewardingDescription"),
        icon: why2,
      },
      {
        title: t("whySection.creditLimitTitle"),
        subTitle: t("whySection.creditLimitDescription"),
        icon: why3,
      },
    ],
  };
  const BoxesList = [
    {
      image: topUp,
      title: t("boxesSection.topupMethodsTitle"),
      description: t("boxesSection.topupMethodsDescription"),
    },
    {
      title: t("boxesSection.businessManagmentTitle"),
      description: t("boxesSection.businessManagmentDescription"),
      image: managment,
    },
    {
      title: t("boxesSection.spendingTitle"),
      description: t("boxesSection.spendingDescription"),
      image: analyticsImage,
    },
    {
      title: t("boxesSection.controlCardTitle"),
      description: t("boxesSection.controlCardDescription"),
      image: card,
    },
  ];
  return (
    <div className="App">
      {resizeListener}
      <Element name="home" className="scroll-section"></Element>
      {sizes.width >= 576 ? <HeaderWeb /> : <HeaderMobile />}
      <FirstSectionWeb />
      <PartnersSection images={partnersImages} />
      {/* <Divider  /> */}
      <Element name="proposition" className="scroll-section"></Element>
      {sizes.width >= 576 ? (
        <SecondSection data={businessBankingSection} />
      ) : (
        <SecondSectionMobile data={businessBankingSection} />
      )}
      {/* <Divider /> */}
      <Element name="who" className="scroll-section">
        <InfoSection infoList={infoList} />
      </Element>
      <Element name="why" className="scroll-section">
        {sizes.width >= 576 ? (
          <SecondSection data={StryveAccountSection} />
        ) : (
          <SecondSectionMobile data={StryveAccountSection} />
        )}
      </Element>
      <BoxesSection list={BoxesList} title={t("boxesSection.inAddation")} />
      <Element name="security" className="scroll-section">
        <SecuritySection />
      </Element>
      <RegisterSection sizes={sizes} />
      <FooterWeb sizes={sizes} />
      <a
        href="https://portal-staging.getstryve.app/join"
        class="float"
        target="_blank"
        rel="noreferrer"
      >
        <img alt={groupStores} className="float-icon" src={groupStores} />
      </a>
      {/* {sizes.width >= 576 ? <FooterWeb sizes={sizes} /> : <FooterMobile />} */}
    </div>
  );
};

export default App;
