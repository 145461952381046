import "./index.scss";

import { useTranslation } from "react-i18next";

const BoxesSection = ({ list, title }) => {
  const { t } = useTranslation();

  return (
    <div className="boxes-section-container">
      <div className="boxes-section">
        <div className="boxes-section-title">{title}</div>

        <div className="boxes-section">
          <div className="boxes-section-item">
            <div className="boxes-section-image">
              <img alt="topUp-icon" src={list[0].image} />
            </div>
            <div>
              <div className="boxes-section-row-item-title">
                {list[0].title}
              </div>
              <div className="boxes-section-row-item-sub-title">
                {list[0].description}
              </div>
            </div>
          </div>
          <div className="boxes-section-item">
            <div>
              <div className="boxes-section-row-item-title">
                {list[1].title}
              </div>
              <div className="boxes-section-row-item-sub-title">
                {list[1].description}
              </div>
            </div>
            <div className="boxes-section-image">
              <img alt="Managment-icon" src={list[1].image} />
            </div>
          </div>
          <div className="boxes-section-item">
            <div>
              <div className="boxes-section-row-item-title">
                {list[2].title}
              </div>
              <div className="boxes-section-row-item-sub-title">
                {list[2].description}
              </div>
            </div>
            <div className="boxes-section-image">
              <img src={list[2].image} alt="analytics-icon" />
            </div>
          </div>
          <div className="boxes-section-item">
            <div className="boxes-section-image">
              <img src={list[3].image} alt="card-managment-icon" />
            </div>
            <div>
              <div className="boxes-section-row-item-title">
                {list[3].title}
              </div>
              <div className="boxes-section-row-item-sub-title">
                {list[3].description}
              </div>
            </div>
          </div>
          {/* {infoList?.map((items) => {
          return item({ ...items });
        })} */}
        </div>
      </div>
    </div>
  );
};

export default BoxesSection;
