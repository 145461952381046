import { useRef } from "react";
import { Carousel } from "antd";

import "./index.scss";
import { useTranslation } from "react-i18next";

const PartnersSection = ({ images }) => {
  const carouselRef = useRef();
  const { t } = useTranslation();
  const handleBeforeChange = (current, next) => {
    if (next === images.length - 1) {
      setTimeout(() => {
        carouselRef.current.slickNext();
      }, 0);
    }
  };

  const settings = {
    autoplay: true,
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    draggable: true,
    swipeToSlide: true,
    touchMove: true,
    cssEase: "linear",
    infinite: true,
    ref: carouselRef,
    beforeChange: handleBeforeChange(),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div className="partners-section-container">
      {/* <div className="partner-list-title">{t("partners.title")}</div> */}
      <div className="partner-list-container">
        <Carousel useCSS={true} adaptiveHeight {...settings}>
          {images.map((partner, index) => (
            <div key={index}>
              <img src={partner} alt={`Partner ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default PartnersSection;
