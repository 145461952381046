import "./index.scss";
import whiteEnglishLogo from "../../assets/logo-white-english.svg";
import whiteArabicLogo from "../../assets/logo-white-arabic.svg";

import { Button, Dropdown, Image, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

const HeaderMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 150);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const logo = {
    ar: whiteArabicLogo,
    en: whiteEnglishLogo,
    // ar: isScrolled ? whiteEnglishLogo : blackArabicLogo,
    // en: isScrolled ? whiteEnglishLogo : blackEnglishLogo,
  };
  // alert(`${isScrolled}`);
  const headerStyles = {
    backgroundColor: isScrolled
      ? "rgba(51, 102, 255, .7)"
      : "rgb(51, 102, 255)",
    borderBottom: " 0.2px solid #eff2f0",
  };
  const onChangeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ar");
      localStorage.setItem("i18nextLng", "ar");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("i18nextLng", "en");
    }
  };
  const menu = (
    <Menu
      onClick={() => setMenuOpen(false)}
      style={{
        paddingTop: "5vw",
      }}
    >
      <Menu.Item key="1">Business</Menu.Item>
      <Menu.Item key="2">Supplier</Menu.Item>
      {/* <Menu.Item key="3">admin Portal</Menu.Item> */}
      <Menu.Item onClick={onChangeLanguage} key="3">
        AR
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="mobile-header" style={headerStyles}>
      <div className="mobile-header-row">
        <Link
          to="home"
          smooth={true}
          className="link-no-pointer"
          duration={500}
        >
          <Image
            preview={false}
            src={logo[i18n.language]}
            className="mobile-header-logo"
          />
        </Link>
        <div className="header-right">
          <Dropdown
            overlayStyle={{
              marginLeft: "90px",
            }}
            overlay={menu}
            visible={menuOpen}
            onVisibleChange={setMenuOpen}
            trigger={["click"]}
          >
            <Button onClick={handleMenuToggle} className="menu-button">
              <MenuOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderMobile;
