import Typed from "typed.js";
import "./index.scss";
import React, { useState, useEffect } from "react";
import mainImage from "../../assets/instant.jpg";

const SupplierFirstSection = ({ data }) => {
  // const { title, mainImage } = data;
  const el = React.useRef(null);
  let title = "title";
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "First sentence.",
        "second sentence.",
        "forthe sentence.",
        "second sentence.",
      ],
      typeSpeed: 50,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <div
      className="supplier-first-section-sontainer"
      style={
        {
          // backgroundColor: backgroundColor,
        }
      }
    >
      <div className="supplier-first-section">
        <div className="supplier-first-section-row">
          <div className="supplier-first-section-right">
            <div className="supplier-first-section-right-row">
              <div className="supplier-first-section-right-row-items">
                <p className="supplier-first-section-subtitle">
                  Stryve is :
                  <span className="text-slider-items" ref={el} />
                </p>
                <p className="supplier-first-section-title">{title}</p>
                <p className="supplier-first-section-title">Button</p>
              </div>
            </div>
          </div>
          <img
            className="supplier-first-section-left"
            alt={title}
            src={mainImage}
          />
        </div>
      </div>
    </div>
  );
};

export default SupplierFirstSection;
