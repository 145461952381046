import { useEffect, useState } from "react";
import useResizeAware from "react-resize-aware";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

import groupStores from "../../assets/groupStores.svg";
import HeaderWeb from "../../components/Header/Header";
import HeaderMobile from "../../components/HeaderMobile/Header";

import "./index.scss";
import FooterWeb from "../../components/Footer/Footer";

const Privacy = () => {
  const [resizeListener, sizes] = useResizeAware();
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState();
  useEffect(() => {
    axios
      .get(
        `/settings/pages?page_key=privacy-policy${
          i18n.language === "ar" ? "-ar" : ""
        }`,
        {}
      )
      .then((response) => {
        setContent(response.data.content);
        return response.data;
      })
      .catch((error) => {});
  }, [i18n.language]);

  return (
    <div className="App">
      {resizeListener}
      <Element name="home" className="scroll-section"></Element>
      {sizes.width >= 576 ? <HeaderWeb /> : <HeaderMobile />}
      <div className="text-container">
        <div
          className="page-container"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>

      <FooterWeb sizes={sizes} />
      <a
        href="https://portal-staging.getstryve.app/join"
        class="float"
        target="_blank"
        rel="noreferrer"
      >
        <img alt={groupStores} className="float-icon" src={groupStores} />
      </a>
      {/* {sizes.width >= 576 ? <FooterWeb sizes={sizes} /> : <FooterMobile />} */}
    </div>
  );
};

export default Privacy;
