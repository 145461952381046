import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import AppStore from "../../assets/appStore.png";
import PlayStore from "../../assets/playStore.png";
import backgroundLast from "../../assets/back-last-4.png";
import mobileBackgroundNoText from "../../assets/firstSection-mobile-no-text.png";

import "./index.scss";

const FirstSectionWeb = () => {
  const { t } = useTranslation();
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log("scrollY", scrollY);
  return (
    <div className="first-section-background">
      <div
        className="first-section"
        // style={{ transform: `translateX(${-scrollY}px)` }}
      >
        <div className="first-section-title">{t("first_section.title")}</div>
        <p className="first-section-subtitle">{t("first_section.subTitle")}</p>
        <div className="first-section-stores">
          <a
            href="https://apps.apple.com/app/id1585808664"
            target="_blank"
            className="first-section-stores-icon"
            rel="noreferrer"
          >
            <img
              src={AppStore}
              alt="app-store-logo"
              className="app-store-logo"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.stryveapp"
            target="_blank"
            className="first-section-stores-icon"
            rel="noreferrer"
          >
            <img
              src={PlayStore}
              alt="play-store-logo"
              className="app-store-logo"
            />
          </a>
        </div>
      </div>

      <div
        className="ing"
        style={{
          transform: `scale(${1 + scrollY * 0.00015})`,
          opacity: scrollY > 570 ? 0.7 : 1,
        }}
      >
        <LazyLoad>
          <img
            style={{
              transform: `scaleX(${1 + scrollY * 0.000015})`,
            }}
            className="left-image"
            src={backgroundLast}
            alt=""
          />
        </LazyLoad>
      </div>
      <div
        className="ping"
        // style={{
        //   transform: `scale(${1 + scrollY * 0.00015})`,
        //   opacity: scrollY > 570 ? 0.7 : 1,
        // }}
      >
        <LazyLoad>
          <img
            src={mobileBackgroundNoText}
            // src={mobileBackground}
            className="first-section-mobile-image"
          />
        </LazyLoad>
        <div className="first-section-mobile-text-container">
          <div className="first-section-mobile-text">Business Banking</div>
          <div className="first-section-mobile-text">Payment Terms</div>
          <div className="first-section-mobile-text">CashBack</div>
          <div className="first-section-mobile-text">Analytics</div>
          {/* <div className="first-section-stores-mobile">
            <a
              href="https://apps.apple.com/app/id1585808664"
              target="_blank"
              className="first-section-stores-icon"
              rel="noreferrer"
            >
              <img
                src={AppStore}
                alt="app-store-logo"
                className="app-store-logo"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.stryveapp"
              target="_blank"
              className="first-section-stores-icon"
              rel="noreferrer"
            >
              <img
                src={PlayStore}
                alt="play-store-logo"
                className="app-store-logo"
              />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FirstSectionWeb;
