import "./index.scss";

const SecondSection = ({ data }) => {
  const { title, subTitle, list, mainImage, backgroundColor } = data;
  return (
    <div
      className="second-section-sontainer"
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div className="second-section">
        <p className="second-section-title">{title}</p>
        <p className="second-section-subtitle">{subTitle}</p>
        <div className="second-section-row">
          <img className="second-section-left" alt={title} src={mainImage} />
          <div className="second-section-right">
            {list?.map((item) => {
              return (
                <div className="second-section-right-row">
                  <img
                    className="second-section-right-icon"
                    src={item?.icon}
                    alt={item.title}
                  />
                  <div className="second-section-right-row-items">
                    <div className="second-section-row-item-title">
                      {item?.title}
                    </div>
                    <div
                      className="second-section-row-item-sub-title"
                      dangerouslySetInnerHTML={{
                        __html: item?.subTitle.replace(/\n/g, "<br>"),
                      }}
                    />

                    {/* <div className="second-section-row-item-sub-title">
                      {item?.subTitle}
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
