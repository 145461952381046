import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import "./locales/i18n";
import { Layout } from "antd";
import "./index.scss";
import Supplier from "./screens/supplier/supplier";
import Terms from "./screens/Terms";
import Privacy from "./screens/Privacy";
import FAQs from "./screens/FAQs";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from "react";
import ReactGA from "react-ga";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/business",
    element: <App />,
  },
  {
    path: "/supplier",
    element: <Supplier />,
  },
  {
    path: "/terms-and-conditions",
    element: <Terms />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
  },
  {
    path: "/faqs",
    element: <FAQs />,
  },
]);

const Root = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || "en";
  const direction = i18n.dir(currentLanguage);

  // // Your web app's Firebase configuration
  // // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  // const firebaseConfig = {
  //   apiKey: "AIzaSyC2-yLKjlTImIKLN-NxVlz_YeUrrlr7Uf8",
  //   authDomain: "stryve-swm.firebaseapp.com",
  //   projectId: "stryve-swm",
  //   storageBucket: "stryve-swm.appspot.com",
  //   messagingSenderId: "550129551017",
  //   appId: "1:550129551017:web:bd84ae74425370f36e8bb8",
  //   measurementId: "G-KT5VRG4W8D",
  // };

  // // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  // console.log("analytics", analytics);
  // useEffect(() => {
  //   logEvent(analytics, "LOG", {
  //     parameter1: "value1",
  //     parameter2: "value2",
  //   });
  // }, []);

  useEffect(() => {
    ReactGA.initialize("G-J2BT9RCZV0");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Helmet>
        <html lang={currentLanguage}></html>

        <title>Stryve</title>
        <meta property="og:title" content="Stryve" />
        <meta property="og:title" content="سترايف" />

        <meta
          property="og:description"
          content="The rental process isn't too complicated—we've outlined a few steps below as well as some things to consider before you sign a lease."
        />
        <link rel="apple-touch-icon" sizes="180x180" href="logo.svg" />
        <link rel="icon" type="image/png" href="logo.svg" sizes="16x16" />

        <meta
          property="og:image"
          content="https://bindex-web.thewebops.com/static/media/images/about-us-landing.jpg"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="200" />
        <meta property="og:url" content="https://getstryve.app/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />

        <meta property="title" content="Stryve" />
        <meta
          property="description"
          content="The rental process isn't too complicated—we've outlined a few steps below as well as some things to consider before you sign a lease."
        />
        <meta
          property="image"
          content="https://bindex-web.thewebops.com/static/media/images/logo.png"
        />
        <meta property="url" content="https://getstryve.app/" />
        <meta property="type" content="website" />
        <meta property="locale" content="en_GB" />
      </Helmet>
      <ConfigProvider direction={direction}>
        <Layout
          style={{
            fontFamily: "unset",
          }}
        >
          <RouterProvider router={router} />
        </Layout>
      </ConfigProvider>
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
