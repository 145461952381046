import { useTranslation } from "react-i18next";

import "./index.scss";

const InfoSection = ({ infoList }) => {
  const { t } = useTranslation();

  const item = ({ title, subTitle, icon, color }) => {
    return (
      <div
        className="info-section-item"
        style={{
          backgroundColor: color,
          border: `1px solid ${color}`,
        }}
      >
        <img alt={title} className="info-section-row-item-icon" src={icon} />
        <div className="info-section-row-item-title">{title}</div>
        <div className="info-section-row-item-sub-title">{subTitle}</div>
      </div>
    );
  };
  return (
    <div className="info-section-container">
      <div className="info-section">
        <div className="info-section-title">{infoList?.title}</div>
        <div className="info-section-list">
          {infoList?.list?.map((items) => {
            return item({ ...items });
          })}
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
