import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import en from "./en.json";
import ar from "./ar.json";
import LanguageDetector from "i18next-browser-languagedetector";

const savedLanguage = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "i18nextLng", // Key for language in localStorage
    },
    resources: {
      ar: {
        translation: ar,
      },
      en: {
        translation: en,
      },
    },
    lng: savedLanguage || "ar",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    // ns: ["common"],
    // defaultNS: "common",
    load: "currentOnly",
  });

export { i18n };
