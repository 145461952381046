import "./index.scss";
import qrCode from "../../assets/qrCode.svg";
import AppStore from "../../assets/appStore.svg";
import PlayStore from "../../assets/playStore.svg";
import { useTranslation } from "react-i18next";

const RegisterSection = ({ sizes }) => {
  const { t } = useTranslation();
  const bullet = () => (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="css-1lm5bh8"
      className="icon-bullet-mobile"
    >
      <g clip-path="url(#a)">
        <path
          d="M4.034 7.649a.485.485 0 0 0 .932 0C5.584 5.499 6 5.084 8.15 4.466a.485.485 0 0 0 0-.932C5.999 2.916 5.584 2.5 4.966.35a.485.485 0 0 0-.932 0C3.416 2.5 3 2.916.85 3.534a.485.485 0 0 0 0 .932C3 5.084 3.416 5.5 4.034 7.65Z"
          fill="black"
        ></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h8v8H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
  const bullets = [
    {
      title: t("registerSection.bullet1"),
      hasBullet: true,
    },
    // {
    //   title: t("registerSection.bullet2"),

    //   hasBullet: sizes.width < 516 ? false : true,
    // },
    {
      title: t("registerSection.bullet3"),

      hasBullet: true,
    },
    {
      title: t("registerSection.bullet4"),

      hasBullet: false,
    },
  ];
  const bulletItem = ({ title, hasBullet }) => {
    return (
      <div className="register-section-bullet-item">
        <div className="register-section-bullet-item-title">
          {bullet()}
          {title}
          {bullet()}
        </div>
        {hasBullet && (
          <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="css-1lm5bh8"
            className="icon-bullet"
          >
            <g clip-path="url(#a)">
              <path
                d="M4.034 7.649a.485.485 0 0 0 .932 0C5.584 5.499 6 5.084 8.15 4.466a.485.485 0 0 0 0-.932C5.999 2.916 5.584 2.5 4.966.35a.485.485 0 0 0-.932 0C3.416 2.5 3 2.916.85 3.534a.485.485 0 0 0 0 .932C3 5.084 3.416 5.5 4.034 7.65Z"
                fill="black"
              ></path>
            </g>
            <defs>
              <clipPath id="a">
                <path
                  fill="#fff"
                  transform="translate(.5)"
                  d="M0 0h8v8H0z"
                ></path>
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
    );
  };
  return (
    <div className="register-section-container">
      <div className="register-section">
        <p className="register-section-subtitle">
          {t("registerSection.title")}
        </p>
        <div className="register-section-bullets">
          {bullets.map((item) => {
            return bulletItem(item);
          })}
        </div>
        {sizes.width > 576 ? (
          <div className="register-section-row">
            <img className="register-section-qr-code" src={qrCode} />
            <div className="register-section-description">
              <div className="register-section-description-title">
                {t("registerSection.scan")}
              </div>
              <div className="register-section-description-sub-title">
                {t("registerSection.availability")}
              </div>
            </div>
          </div>
        ) : (
          <div className="register-section-mobile">
            <a
              href="https://apps.apple.com/app/{your-app-id}"
              target="_blank"
              className="register-section-mobile-icon"
            >
              <img src={AppStore} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id={your-app-package-name}"
              target="_blank"
              className="register-section-mobile-icon"
            >
              <img src={PlayStore} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterSection;
