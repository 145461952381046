import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { Divider, Image } from "antd";
import { Footer } from "antd/es/layout/layout";
import { WhatsAppOutlined, PhoneOutlined } from "@ant-design/icons";

import logoIcon from "../../assets/logoIcon.png";
import Facebook from "../../assets/facebook.svg";
import Instagram from "../../assets/instagram.svg";
import Youtube from "../../assets/youtube.svg";
import Linkedin from "../../assets/linkedin.svg";
import AppStore from "../../assets/appStore.png";
import PlayStore from "../../assets/playStore.png";
import "./index.scss";

const FooterWeb = ({ sizes }) => {
  const { t, i18n } = useTranslation();
  const footerList = [
    {
      title: t("footerSection.smeBanking"),
      list: [
        { title: t("footerSection.proposition"), naviagte: "proposition" },
        { title: t("footerSection.who"), naviagte: "who" },
        { title: t("footerSection.why"), naviagte: "why" },
        { title: t("footerSection.security"), naviagte: "security" },
        // "Invoices",
      ],
    },
    {
      title: t("footerSection.aboutUs"),
      list: [
        {
          title: t("footerSection.terms"),
          link: "/terms-and-conditions",
        },
        {
          title: t("footerSection.privacy"),
          link: "/privacy-policy",
        },
        { title: t("footerSection.faqs"), link: "/faqs" },
      ],
    },
  ];

  const footerCategory = ({ title, list }) => {
    return (
      <div className="site-footer-category">
        <div className="site-footer-category-title">{title}</div>
        {list?.map((item) => {
          if (item.link) {
            return (
              <a
                href={item.link}
                // target="_blank"
                // className="first-section-stores-icon"
                // rel="noreferrer"
              >
                <div className="site-footer-category-sub-title">
                  {item?.title}
                </div>
              </a>
            );
          } else {
            return (
              <Link to={item.naviagte} smooth={true} duration={500}>
                <div className="site-footer-category-sub-title">
                  {item?.title}
                </div>
              </Link>
            );
          }
        })}
      </div>
    );
  };

  const footerFollow = () => (
    <div className="site-footer-bottom-right">
      <div className="site-footer-bottom-right-text">
        {t("footerSection.question")}
      </div>
      <a
        href="mailto:support@getstryve.app"
        className="site-footer-bottom-right-description"
      >
        support@getstryve.app
      </a>
      <a
        href="https://wa.me/+201555506718"
        className="site-footer-bottom-right-description"
        target="_blank"
        rel="noreferrer"
      >
        <WhatsAppOutlined /> +20 1 55 550 6718
      </a>
      <a
        target="_blank"
        href="tel:+20223229600"
        className="site-footer-bottom-right-description"
        rel="noreferrer"
      >
        <PhoneOutlined rotate={90} /> +20 2 2322 9600
      </a>

      <div className="site-footer-bottom-right-text">
        {t("footerSection.followUs")}
      </div>
      <div className="site-footer-bottom-right-logos-container">
        <a
          target="_blank"
          href="https://www.facebook.com/getstryveapp"
          rel="noreferrer"
        >
          <img
            src={Facebook}
            alt="facebook-icon"
            className="site-footer-bottom-logo"
          />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/getstryveapp/"
          rel="noreferrer"
        >
          <img
            src={Instagram}
            alt="instagram-icon"
            className="site-footer-bottom-logo"
          />
        </a>
        <a target="_blank" href="/" rel="noreferrer">
          <img
            src={Youtube}
            alt="youtube-icon"
            className="site-footer-bottom-logo"
          />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/getstryveapp/posts/?feedView=all"
          rel="noreferrer"
        >
          <img
            src={Linkedin}
            alt="twitter-icon"
            className="site-footer-bottom-logo"
          />
        </a>
      </div>
      <div className="first-section-stores-container">
        <a
          href="https://apps.apple.com/app/id1585808664"
          target="_blank"
          className="first-section-stores-icon"
          rel="noreferrer"
        >
          <img
            src={AppStore}
            alt="app-store-logo"
            className="site-footer-bottom-store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.stryveapp"
          target="_blank"
          className="first-section-stores-icon"
          rel="noreferrer"
        >
          <img
            src={PlayStore}
            alt="play-store-logo"
            className="site-footer-bottom-store"
          />
        </a>
      </div>
    </div>
  );
  const footerMobileCategory = ({ title, list }) => (
    <div className="site-footer-mobile-bottom">
      <div className="site-footer-mobile-bottom-title">{title}</div>
      <div className="site-footer-mobile-bottom-subtitle-container">
        {list.map((item) => {
          if (item.link) {
            return (
              <a href={item.link} target="_blank" rel="noreferrer">
                <div className="site-footer-mobile-bottom-subtitle">
                  {item?.title}
                </div>
              </a>
            );
          } else {
            return (
              <Link to={item.naviagte} smooth={true} duration={500}>
                <div className="site-footer-mobile-bottom-subtitle">
                  {item?.title}
                </div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
  return (
    <>
      <Footer className="site-footer">
        <div className="site-footer-container">
          <div className="site-footer-row">
            <div>
              <div className="site-footer-logo-title">
                <Image
                  preview={false}
                  src={logoIcon}
                  className="site-footer-logo"
                />
                <div className="site-footer-title">
                  {t("footerSection.title")}
                </div>
              </div>
            </div>
            <Divider />

            {sizes.width > 768 ? (
              <div className="site-footer-bottom">
                <div className="side-footer-bottom-left">
                  {footerList.map((item) => {
                    return footerCategory({ ...item });
                  })}
                </div>
                {footerFollow()}
              </div>
            ) : (
              <div className="site-footer-mobile-bottom-container">
                {footerList.map((item) => {
                  return footerMobileCategory({ ...item });
                })}
              </div>
            )}
            {/* <Divider /> */}

            {sizes.width > 768 && (
              <>
                <Divider />

                <div className="site-footer-bottom-text">
                  {t("footerSection.description1")}
                </div>
              </>
            )}
          </div>
        </div>
      </Footer>

      {sizes.width < 769 && (
        <Footer className="site-footer-mobile-bottom-new">
          <div className="site-footer-row">
            <div className="site-footer-mobile-bottom-container">
              {footerFollow()}
            </div>
          </div>
          <Divider />

          <div className="site-footer-bottom-text">
            {t("footerSection.description1")}
          </div>
        </Footer>
      )}
    </>
  );
};

export default FooterWeb;
