import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Image } from "antd";
import { Header } from "antd/es/layout/layout";

import whiteEnglishLogo from "../../assets/logo-white-english.svg";
import whiteArabicLogo from "../../assets/logo-white-arabic.svg";
import arrow from "../../assets/arrow.svg";

import "./index.scss";
import { Link } from "react-scroll";

const HeaderWeb = () => {
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 150);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyles = {
    backgroundColor: isScrolled
      ? "rgba(51, 102, 255, .7)"
      : "rgb(51, 102, 255)",
  };
  const logo = {
    ar: whiteArabicLogo,
    en: whiteEnglishLogo,
  };
  const onChangeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ar");
      localStorage.setItem("i18nextLng", "ar");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("i18nextLng", "en");
    }
  };
  return (
    <Header className="site-header" style={headerStyles}>
      <div className="site-header-row">
        <div className="site-header-row-left">
          <Link
            className="link-no-pointer"
            to="home"
            smooth={true}
            duration={500}
          >
            <Image
              preview={false}
              src={logo[i18n.language]}
              className="site-header-logo"
              alt="Stryve-Logo"
            />
          </Link>
          <a href={"/business"} className={"site-header-item"}>
            {t("Home.title")}
          </a>
          {/* <a href={"/supplier"} className={"site-header-item"}>
            {t("Home.supplier")}
          </a> */}
        </div>
        <div className="site-header-right-container">
          <a className="site-header-language" onClick={onChangeLanguage}>
            {i18n.language === "ar" ? "en" : "عربي"}
          </a>
          <a
            href="https://portal.getstryve.app/login"
            target="_blank"
            className="site-header-supplier"
            rel="noreferrer"
          >
            <div>{t("headerSection.supplier")}</div>
            <Image preview={false} src={arrow} className="site-header-arrow" />
          </a>
        </div>
      </div>
    </Header>
  );
};

export default HeaderWeb;
