import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import "./index.scss";

const RowSection = ({ infoList }) => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.5, // When 50% of the section is in the viewport
  });
  console.log("inView", inView);
  const item = ({ title, subTitle, icon, color, index }) => {
    return (
      <div
        className="row-section-item"
        style={
          {
            // backgroundColor: color,
            // border: `1px solid black`,
          }
        }
      >
        <h1 className="row-section-row-item-number">{index + 1}</h1>

        <div className="row-section-row-item-continer">
          <img alt={title} className="row-section-row-item-icon" src={icon} />
          <div className="row-section-row-item-sub-title">{title}</div>
        </div>
      </div>
    );
  };
  return (
    <div ref={ref} className="row-section-container">
      <div className="row-section">
        <div className="row-section-title">{infoList?.title}</div>
        {inView && (
          <div className="row-section-list">
            {infoList?.list?.map((items, index) => {
              return item({ ...items, index });
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RowSection;
