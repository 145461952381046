import card1 from "../../assets/card1.svg";
import "./index.scss";

const SecondSectionMobile = ({ data }) => {
  const rowItem = ({ title, subTitle }) => {
    return (
      <>
        <div className="divider"></div>
        <div className="second-section-mobile-row-items">
          <div className="second-section-mobile-row-item-title">{title}</div>
          <div className="second-section-mobile-row-item-sub-title">
            {subTitle}
          </div>
        </div>
      </>
    );
  };
  console.log("data", data);
  return (
    <div
      className="container-second-section"
      style={{
        backgroundColor: data?.backgroundColor,
      }}
    >
      <div className="second-section-mobile">
        <p className="second-section-mobile-title">{data?.title}</p>
        <div className="second-section-mobile-sub-container">
          <p className="second-section-mobile-subtitle">{data?.subTitle}</p>
          <img
            className="second-section-mobile-sub-container-icon"
            src={data?.mainImageMobile}
            alt="icon"
          ></img>
        </div>
        <div className="second-section-mobile-row">
          {data?.list?.map((item) => {
            return rowItem({
              ...item,
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default SecondSectionMobile;
