import { useTranslation } from "react-i18next";

import pci from "../../assets/icons/pci.svg";
import cbe from "../../assets/icons/cbe.svg";
import encryption from "../../assets/icons/encryption.svg";

import "./index.scss";

const SecuritySection = () => {
  const { t } = useTranslation();
  const securityList = [
    {
      img: pci,
      title: t("securitySection.standards"),
    },
    {
      img: cbe,
      title: t("securitySection.licensed"),
    },
    {
      img: encryption,
      title: t("securitySection.dataEncryption"),
    },
  ];
  const securityItem = ({ title, img }) => {
    return (
      <div className="security-section-list-item">
        <img className="security-section-list-item-icon" src={img}></img>
        <div className="security-section-list-item-title">{title}</div>
      </div>
    );
  };
  return (
    <div className="security-section-container">
      <div className="security-section">
        <p className="security-section-title">{t("securitySection.title")}</p>
        <p className="security-section-subtitle">
          {t("securitySection.subTitle")}
        </p>
        <div className="security-section-list">
          {securityList.map((item) => {
            return securityItem({ ...item });
          })}
        </div>
      </div>
    </div>
  );
};

export default SecuritySection;
