import useResizeAware from "react-resize-aware";

import "./supplier.scss";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import InfoSection from "../../components/InfoSection/InfoSection";
import FooterWeb from "../../components/Footer/Footer";
import SecondSectionMobile from "../../components/SecondSectionMobile/SecondSectionMobile";
import BoxesSection from "../../components/BoxesSection/BoxesSection";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import business1 from "../../assets/icons/business-1.svg";
import business2 from "../../assets/icons/business-2.svg";
import business3 from "../../assets/icons/business-3.svg";
import business from "../../assets/icons/business.svg";
import businessMobile from "../../assets/icons/businessMobile.svg";
import instant from "../../assets/instant.jpg";

import why1 from "../../assets/icons/why-1.svg";
import why2 from "../../assets/icons/why-2.svg";
import why3 from "../../assets/icons/why-3.svg";
import why from "../../assets/icons/why.svg";
import whyMobile from "../../assets/icons/whyMobile.svg";

import groupStores from "../../assets/groupStores.svg";
import FirstSectionWeb from "../../components/FirstSection/FirstSection";
import HeaderWeb from "../../components/Header/Header";
import HeaderMobile from "../../components/HeaderMobile/Header";

import CBE from "../../assets/logos/CBE.png";
import MDP from "../../assets/logos/mdp.png";
import AAIB from "../../assets/logos/AAIB.png";
import LDC from "../../assets/logos/LDC.png";
import MEEZA from "../../assets/logos/MEEZA.png";
import MASARY from "../../assets/logos/masary.png";
import AFS from "../../assets/logos/AFS.png";
import GRINTA from "../../assets/logos/grinta.png";
import YALLA from "../../assets/logos/yalla.png";
import SANDAH from "../../assets/logos/sandah.png";
import BASATA from "../../assets/logos/BASATA.png";

import info1 from "../../assets/icons/info-1.svg";
import info2 from "../../assets/icons/info-2.svg";
import info3 from "../../assets/icons/info-3.svg";
import info4 from "../../assets/icons/info-4.svg";

import topUp from "../../assets/icons/topup.svg";
import analytics from "../../assets/icons/analytics.svg";
import card from "../../assets/icons/card.svg";
import managment from "../../assets/icons/managment.svg";

import videoSource from "../../assets/video.mp4";
import { useEffect, useRef } from "react";
import RowSection from "../../components/RowSection/rowSection";
import SimpleSection from "../../components/SimpleSection/SimpleSection";
import SupplierFirstSection from "../../components/SupplierFirstSection/SupplierFirstSection";
import SupplierSecondSection from "../../components/SupplierSecondSection/SupplierSecondSection";

const Supplier = () => {
  const [resizeListener, sizes] = useResizeAware();
  const { t } = useTranslation();
  const videoRef = useRef();
  useEffect(() => {
    // videoRef.current.play();
  }, []);
  const rowList = {
    title: t("howStryveWorksSection.title"),
    list: [
      {
        title: t("howStryveWorksSection.merchant"),
        icon: info1,
      },
      {
        title: t("howStryveWorksSection.approve"),
        icon: info2,
      },
      {
        title: t("howStryveWorksSection.sendInvoice"),
        icon: info3,
      },
      {
        title: t("howStryveWorksSection.receive"),

        icon: info1,
      },
      {
        title: t("howStryveWorksSection.pays"),

        icon: info2,
      },
      {
        title: t("howStryveWorksSection.paid"),

        icon: info3,
      },
      {
        title: t("howStryveWorksSection.collect"),

        icon: info3,
      },
    ],
  };
  const infoList = {
    title: t("infoSection.title"),
    list: [
      {
        title: t("infoSection.checkoutTitle"),

        subTitle: t("infoSection.checkoutDescription"),
        icon: info1,
        color: "#D6E0FF",
      },
      {
        title: t("infoSection.agentTitle"),
        subTitle: t("infoSection.agentDescription"),
        icon: info2,
        color: "#FFEBD8",
      },
      {
        title: t("infoSection.bulkTitle"),
        subTitle: t("infoSection.bulkDescription"),
        icon: info3,
        color: "#EBD6FF",
      },
      {
        title: t("infoSection.erpTitle"),
        subTitle: t("infoSection.erpDescription"),
        icon: info3,
        color: "#EBD6FF",
      },
    ],
  };
  const partnersImages = [
    CBE,
    AAIB,
    MDP,
    LDC,
    MEEZA,
    BASATA,
    MASARY,
    AFS,
    GRINTA,
    YALLA,
    SANDAH,
  ];
  const businessBankingSection = {
    title: t("supplierBankingSection.title"),
    subTitle: t("supplierBankingSection.subTitle"),
    mainImage: instant,
    mainImageMobile: businessMobile,
    backgroundColor: "white",
    list: [
      {
        title: t("supplierBankingSection.boostSalesTitle"),
        subTitle: t("supplierBankingSection.boostSalesDescription"),
        icon: business1,
      },
      {
        title: t("supplierBankingSection.getPaidTitle"),
        subTitle: t("supplierBankingSection.getPaidDescription"),
        icon: business2,
      },
      {
        title: t("supplierBankingSection.eleminateAdminTitle"),
        subTitle: t("supplierBankingSection.eleminateAdminDescription"),
        icon: business3,
      },
      {
        title: t("supplierBankingSection.costEffectiveTitle"),
        subTitle: t("supplierBankingSection.costEffectiveDescription"),
        icon: business1,
      },
    ],
  };
  const StryveAccountSection = {
    title: t("whoShouldSection.title"),
    subTitle: t("whoShouldSection.subTitle"),
    mainImage: why,
    mainImageMobile: whyMobile,
    backgroundColor: "#ebd6ff",
    list: [
      {
        title: t("whoShouldSection.offlineSuppliersTitle"),
        subTitle: t("whoShouldSection.offlineSuppliersDescription"),
        icon: why1,
      },
      {
        title: t("whoShouldSection.onlineSuppliersTitle"),
        subTitle: t("whoShouldSection.onlineSuppliersDescription"),
        icon: why2,
      },
      {
        title: t("whoShouldSection.B2BTitle"),
        subTitle: t("whoShouldSection.B2BDescription"),
        icon: why3,
      },
    ],
  };

  const BoxesList = [
    {
      image: topUp,
      title: t("supplierBoxesSection.B2BDigitalTitle"),
      description: t("supplierBoxesSection.B2BDigitalDescription"),
    },
    {
      title: t("supplierBoxesSection.seamlessTitle"),
      description: t("supplierBoxesSection.seamlessDescription"),
      image: managment,
    },
    {
      title: t("supplierBoxesSection.B2BBNPLlTitle"),
      description: t("supplierBoxesSection.B2BBNPLlDescription"),
      image: analytics,
    },
    {
      title: t("supplierBoxesSection.reconciliationTitle"),
      description: t("supplierBoxesSection.reconciliationDescription"),
      image: card,
    },
  ];

  return (
    <div className="App">
      {resizeListener}
      <Element name="home" className="scroll-section"></Element>
      {sizes.width >= 576 ? <HeaderWeb /> : <HeaderMobile />}

      <SupplierFirstSection data={businessBankingSection} />
      <PartnersSection images={partnersImages} />

      <div className="video-container">
        <video
          controls
          // width="100%"
          // height="100%"
          src={videoSource}
          poster="video-poster.jpg"
          autoPlay
          muted // Muted for better autoplay support
          ref={videoRef}
          className="video-player"
        >
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <Divider  /> */}
      <Element name="proposition" className="scroll-section"></Element>
      {sizes.width >= 576 ? (
        <SupplierSecondSection data={businessBankingSection} />
      ) : (
        <SecondSectionMobile data={businessBankingSection} />
      )}

      <RowSection infoList={rowList} />

      {/* <Divider /> */}
      <Element name="who" className="scroll-section">
        <InfoSection infoList={infoList} />
      </Element>
      <Element name="why" className="scroll-section">
        {sizes.width >= 576 ? (
          <SupplierSecondSection data={StryveAccountSection} />
        ) : (
          <SecondSectionMobile data={StryveAccountSection} />
        )}
      </Element>
      <BoxesSection list={BoxesList} title={t("supplierBoxesSection.title")} />
      <Element name="security" className="scroll-section">
        {/* <SecuritySection /> */}
      </Element>
      {/* <RegisterSection sizes={sizes} /> */}
      <SimpleSection sizes={sizes} />
      <FooterWeb sizes={sizes} />
      <a
        href="https://portal-staging.getstryve.app/join"
        class="float"
        target="_blank"
        rel="noreferrer"
      >
        <img alt={groupStores} className="float-icon" src={groupStores} />
      </a>
      {/* {sizes.width >= 576 ? <FooterWeb sizes={sizes} /> : <FooterMobile />} */}
    </div>
  );
};

export default Supplier;
